// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 960px,
  lg: 1200px,
  xl: 1600px,
  xxl: 2000px
  );

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1600px
);