// Forms
// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: $text-muted;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;

$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $body-bg;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: null;

$input-color: $body-color;
$input-border-color: #666666;
$input-border-width: $input-btn-border-width;
// $input-box-shadow:                      $box-shadow-inset;

// $input-border-radius:                   $border-radius;
// $input-border-radius-sm:                $border-radius-sm;
// $input-border-radius-lg:                $border-radius-lg;

// $input-focus-bg:                        $input-bg;
// $input-focus-border-color:              tint-color($component-active-bg, 50%);
// $input-focus-color:                     $input-color;
// $input-focus-width:                     0; // $input-btn-focus-width;
// $input-focus-box-shadow:                none; // $input-btn-focus-box-shadow;

// $input-placeholder-color:               $gray-600;
// $input-plaintext-color:                 $body-color;

// $input-height-border:                   $input-border-width * 2;

// $input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
// $input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
// $input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5);

// $input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
// $input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
// $input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

// $input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// $form-color-width:                      3rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
// $form-check-input-width:                  1em;
// $form-check-min-height:                   $font-size-base * $line-height-base;
// $form-check-padding-start:                $form-check-input-width + .5em;
// $form-check-margin-bottom:                .5rem; // .125rem;
// $form-check-label-color:                  null;
// $form-check-label-cursor:                 null;
// $form-check-transition:                   null;

// $form-check-input-active-filter:          brightness(90%);

$form-check-input-bg: $input-bg;
// $form-check-input-border:                 1px solid rgba($black, .25);
// $form-check-input-border-radius:          .25em;
// $form-check-radio-border-radius:          50%;
// $form-check-input-focus-border:           $input-focus-border-color;
// $form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;

// $form-check-input-checked-color:          $component-active-color;
// $form-check-input-checked-bg-color:       $component-active-bg;
// $form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
// $form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
// $form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

// $form-check-input-indeterminate-color:          $component-active-color;
// $form-check-input-indeterminate-bg-color:       $component-active-bg;
// $form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;
// $form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

// $form-check-input-disabled-opacity:        .5;
// $form-check-label-disabled-opacity:        $form-check-input-disabled-opacity;
// $form-check-btn-check-disabled-opacity:    $btn-disabled-opacity;

// $form-check-inline-margin-end:    .3rem; // 1rem;
// // scss-docs-end form-check-variables

// // scss-docs-start form-switch-variables
// $form-switch-color:               rgba(0, 0, 0, .25);
// $form-switch-width:               2em;
// $form-switch-padding-start:       $form-switch-width + .5em;
// $form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
// $form-switch-border-radius:       $form-switch-width;
// $form-switch-transition:          background-position .15s ease-in-out;

// $form-switch-focus-color:         $input-focus-border-color;
// $form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

// $form-switch-checked-color:       $component-active-color;
// $form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
// $form-switch-checked-bg-position: right center;
// // scss-docs-end form-switch-variables

// // scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-disabled-color: null;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"/></svg>');

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 +
  $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
// $form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $border-radius;
// $form-select-box-shadow:          $box-shadow-inset;

$form-select-focus-border-color: none;
$form-select-focus-width: none;
$form-select-focus-box-shadow: none;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;

// $form-select-transition:          $input-transition;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
// $form-range-track-width:          100%;
// $form-range-track-height:         .5rem;
// $form-range-track-cursor:         pointer;
// $form-range-track-bg:             $gray-300;
// $form-range-track-border-radius:  1rem;
// $form-range-track-box-shadow:     $box-shadow-inset;

// $form-range-thumb-width:                   1rem;
// $form-range-thumb-height:                  $form-range-thumb-width;
// $form-range-thumb-bg:                      $component-active-bg;
// $form-range-thumb-border:                  0;
// $form-range-thumb-border-radius:           1rem;
// $form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
// $form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
// $form-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in Edge
// $form-range-thumb-active-bg:               tint-color($component-active-bg, 70%);
// $form-range-thumb-disabled-bg:             $gray-500;
// $form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
// $form-file-button-color:          $input-color;
// $form-file-button-bg:             $input-group-addon-bg;
// $form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%);
// // scss-docs-end form-file-variables

// // scss-docs-start form-floating-variables
// $form-floating-height:            add(3.5rem, $input-height-border);
// $form-floating-line-height:       1.25;
// $form-floating-padding-x:         $input-padding-x;
// $form-floating-padding-y:         1rem;
// $form-floating-input-padding-t:   1.625rem;
// $form-floating-input-padding-b:   .625rem;
// $form-floating-label-opacity:     .65;
// $form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem);
// $form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

// Form validation

// scss-docs-start form-feedback-variables
// $form-feedback-margin-top:          $form-text-margin-top;
// $form-feedback-font-size:           $form-text-font-size;
// $form-feedback-font-style:          $form-text-font-style;
// $form-feedback-valid-color:         $success;
// $form-feedback-invalid-color:       $danger;

// $form-feedback-icon-valid-color:    $form-feedback-valid-color;
// $form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
// $form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
// $form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

// // scss-docs-start form-validation-states
// $form-validation-states: (
//  "valid": (
//    "color": $form-feedback-valid-color,
//    "icon": $form-feedback-icon-valid
//  ),
//  "invalid": (
//    "color": $form-feedback-invalid-color,
//    "icon": $form-feedback-icon-invalid
//  )
// );

$input-focus-border-color: $primary;
$input-btn-focus-box-shadow: 0px 0px 0px 2px $formBlueFocus;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-focus-border: 2px;
$input-focus-border-width: $input-focus-border;
$invalid-focus-box-shadow-color: #ff99ac;
$valid-focus-box-shadow-color: #c8f7ba;
$valid-hover-bg: #e2ffc2;

.form-control {
    border: 1px solid #666 !important;
    border-radius: 4px !important;
    font-family: "Founders Regular" !important;
}

.is-invalid {
  &.is-focus,
  &:focus {
    border: $input-focus-border-width solid $danger !important;
    box-shadow: 0px 0px 0px 2px $invalid-focus-box-shadow-color !important;
  }
}

.is-valid {
  &.is-focus,
  &:focus {
    border: $input-focus-border-width solid $success !important;
    box-shadow: 0px 0px 0px 2px $valid-focus-box-shadow-color !important;
  }
  &:hover {
    background-color: $valid-hover-bg !important;
  }
}

.is-hover {
  border: 2px solid $primary;
  box-shadow: none;
}

.is-invalid {
  &.is-hover,
  &:hover {
    background-color: $formRedHover !important;
  }
}

.form-control.is-valid.is-hover {
  background-color: $valid-hover-bg !important;
}

.form-control:focus {
  border: $input-focus-border-width solid $input-focus-border-color;
}

.is-focus {
  border: $input-focus-border-width solid $primary !important;
  box-shadow: 0px 0px 0px 2px $formBlueFocus !important;
}

button,
.btn {
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-image: none;
}

select {
  border-right: solid 48px $primary;
}

