html, body {
    font-size: 12px; }
  
  h1 {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media (min-width: 500px) {
      h1 {
        font-size: 6rem;
        line-height: 5.05rem; } }
    @media (min-width: 600px) {
      h1 {
        font-size: 6rem;
        line-height: 5.05rem; } }
    @media (min-width: 800px) {
      h1 {
        font-size: 7rem;
        line-height: 6.05rem; } }
    @media (min-width: 1024px) {
      h1 {
        font-size: 7rem;
        line-height: 6.05rem; } }
    @media (min-width: 1500px) {
      h1 {
        font-size: 7rem;
        line-height: 6.05rem; } }
  
  h2 {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 1.15rem;
    margin-top: 5px;
    margin-bottom: 5px; }
    @media (min-width: 500px) {
      h2 {
        font-size: 1.625rem;
        line-height: 1.15rem; } }
    @media (min-width: 600px) {
      h2 {
        font-size: 1.625rem;
        line-height: 1.15rem; } }
    @media (min-width: 800px) {
      h2 {
        font-size: 2rem;
        line-height: 1.25rem; } }
    @media (min-width: 1024px) {
      h2 {
        font-size: 2.25rem;
        line-height: 2.25rem; } }
    @media (min-width: 1500px) {
      h2 {
        font-size: 2.25rem;
        line-height: 2.25rem; } }
  
  h3 {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.13rem;
    margin-top: 5px;
    margin-bottom: 5px; }
    @media (min-width: 500px) {
      h3 {
        font-size: 1.375rem;
        line-height: 1.13rem; } }
    @media (min-width: 600px) {
      h3 {
        font-size: 1.375rem;
        line-height: 1.13rem; } }
    @media (min-width: 800px) {
      h3 {
        font-size: 1.5rem;
        line-height: 1.25rem; } }
    @media (min-width: 1024px) {
      h3 {
        font-size: 1.75rem;
        line-height: 1.25rem; } }
    @media (min-width: 1500px) {
      h3 {
        font-size: 1.75rem;
        line-height: 1.25rem; } }
  
  h4 {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 8px;
    margin-bottom: 8px; }
    @media (min-width: 500px) {
      h4 {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 600px) {
      h4 {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 800px) {
      h4 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
    @media (min-width: 1024px) {
      h4 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
    @media (min-width: 1500px) {
      h4 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
  
  h5 {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 5px;
    margin-bottom: 5px; }
    @media (min-width: 500px) {
      h5 {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 600px) {
      h5 {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 800px) {
      h5 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
    @media (min-width: 1024px) {
      h5 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
    @media (min-width: 1500px) {
      h5 {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
  
  .header li {
    font-family: "Abel", sans-serif;
    color: #FFFFFF;
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.25rem; }
    @media (min-width: 500px) {
      .header li {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 600px) {
      .header li {
        font-size: 1.25rem;
        line-height: 1.25rem; } }
    @media (min-width: 800px) {
      .header li {
        font-size: 1.5rem;
        line-height: 1.45rem; } }
    @media (min-width: 1024px) {
      .header li {
        font-size: 1rem;
        font-size: 1.25rem; } }
    @media (min-width: 1500px) {
      .header li {
        font-size: 1rem;
        font-size: 1.25rem; } }
  
  span, a, li, p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 1.25;
    font-size: 1rem;
    font-size: 1.25rem; }
  
  /*
  
      CSS Animation rules
  
  */
  /*
  
      Mixins
  
  */
  /*
  
      Set Body Rules
  
  */
  html {
    background-color: #212121; }
  
  html,
  body {
    margin: 0;
    padding: 0;
    color: white; }
  
  body {
    overflow: hidden; }
  
  [data-whatintent='keyboard'] :focus {
    outline-color: #212121; }
  
  [data-whatintent='mouse'] :focus {
    outline-color: transparent;
    outline-style: none; }
  
  [data-whatintent='touch'] :focus {
    outline-color: transparent;
    outline-style: none; }
  
  ::selection {
    background: #212121; }
  
  ::-moz-selection {
    background: #212121; }
  
  /*
  
      Section Styles
  
  */
  .switch-wrapper {
    position: relative; }
  
  .switch-wrapper > div {
    position: absolute; }
  
  .content-block {
    height: 100vh;
    width: 100vw;
    display: block;
    position: relative; }
  
  .content-wrap {
    display: block;
    clear: both;
    position: relative;
    width: calc(100%);
    float: left;
    margin-left: 0rem;
    margin-top: 20px;
    padding-bottom: 20px; }
    .content-wrap:first-child {
      margin-top: 80px; }
    @media (min-width: 500px) {
      .content-wrap {
        margin-top: 20px;
        padding-bottom: 20px; } }
    @media (min-width: 800px) {
      .content-wrap.text-content {
        width: calc(75%);
        float: left;
        margin-left: 0rem; } }
    @media (min-width: 1024px) {
      .content-wrap.text-content {
        width: calc(62.5%);
        float: left;
        margin-left: 0rem; } }
    @media (min-width: 1500px) {
      .content-wrap.text-content {
        width: calc(50%);
        float: left;
        margin-left: 0rem; } }
    @media (min-width: 1024px) {
      .content-wrap {
        margin-top: 30px;
        padding-bottom: 30px; } }
    @media (min-width: 1500px) {
      .content-wrap {
        margin-top: 40px;
        padding-bottom: 40px; } }
  
  .centred {
    width: calc(90.625%);
    float: left;
    margin-left: 0rem;
    margin-left: calc(3.125% + 0rem);
    text-align: left;
    scroll-behavior: auto;
    scroll-behavior: smooth;
    height: 100vh; }


/* Generic */
.digit {
    line-height: 1.25;
    font-size: 1rem; }
  
  .award {
    display: inline-block;
    border-radius: 2px;
    padding-bottom: 40px; }
    @media (min-width: 500px) {
      .award {
        width: calc(100%);
        float: left;
        margin-left: 0rem;
        padding-bottom: 50px; } }
    @media (min-width: 600px) {
      .award {
        width: calc(50%);
        float: left;
        margin-left: 0rem;
        padding-bottom: 80px;
        min-height: 120px; } }
    @media (min-width: 800px) {
      .award {
        width: calc(50%);
        float: left;
        margin-left: 0rem; } }
    @media (min-width: 1024px) {
      .award {
        width: calc(31.25%);
        float: left;
        margin-left: 0rem;
        padding-bottom: 70px;
        min-height: 150px; } }
    @media (min-width: 1500px) {
      .award {
        width: calc(25%);
        float: left;
        margin-left: 0rem;
        padding-bottom: 50px;
        min-height: 150px; } }
  
  /*
  
      Menu
  
  */
  .menu {
    display: inline;
    position: relative; }
    .menu .menu-button {
      z-index: 3000;
      position: fixed;
      top: 20px;
      right: 20px;
      display: inline-block;
      padding: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #212121;
      border: 0px solid #ccc;
      cursor: pointer; }
    .menu .burger-icon {
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .menu .burger-icon, .menu .burger-icon::before, .menu .burger-icon::after {
        display: block;
        width: 20px;
        height: 3px;
        background-color: #FFFFFF;
        outline: 1px solid transparent;
        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s; }
      .menu .burger-icon::before, .menu .burger-icon::after {
        position: absolute;
        content: ""; }
      .menu .burger-icon::before {
        top: -8px; }
      .menu .burger-icon::after {
        top: 8px; }
    .menu .menu-button.is-active .burger-icon {
      background-color: transparent; }
    .menu .menu-button.is-active .burger-icon::before {
      -webkit-transform: translateY(8px) rotate(45deg);
      -moz-transform: translateY(8px) rotate(45deg);
      -ms-transform: translateY(8px) rotate(45deg);
      -o-transform: translateY(8px) rotate(45deg);
      transform: translateY(8px) rotate(45deg); }
    .menu .menu-button.is-active .burger-icon::after {
      -webkit-transform: translateY(-8px) rotate(-45deg);
      -moz-transform: translateY(-8px) rotate(-45deg);
      -ms-transform: translateY(-8px) rotate(-45deg);
      -o-transform: translateY(-8px) rotate(-45deg);
      transform: translateY(-8px) rotate(-45deg); }
    .menu .menu-body.collapse {
      transition-delay: 0.4s;
      right: calc(((100vw / 31) * 32) * -1); }
    .menu .menu_bg {
      pointer-events: none;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #212121; }
    .menu .menu-body {
      position: fixed;
      right: 0;
      height: calc(100vh + 12px);
      top: -12px;
      display: block;
      width: calc(100%);
      float: left;
      margin-left: 0rem;
      z-index: 100;
      background-color: #212121;
      padding-left: 0;
      transition: right 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.1s;
      z-index: 2500;
      overflow: scroll; }
      .menu .menu-body li {
        margin-left: calc(3.125% + 0rem);
        list-style: none;
        display: block;
        padding-left: 10px;
        text-align: left; }
    .menu .social a {
      display: block;
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      background-repeat: no-repeat;
      background-size: 120px;
      width: 120px;
      display: block; }

  /*
  
      Logo
  
  */

  .logo {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 100;
    width: 4.5rem;
    display: none; }
    @media (min-width: 500px) {
      .logo {
        width: 5.5rem; } }
    @media (min-width: 600px) {
      .logo {
        width: 5.5rem; } }
    @media (min-width: 800px) {
      .logo {
        width: 5.5rem; } }
    @media (min-width: 1024px) {
      .logo {
        width: 7rem; } }
    @media (min-width: 1500px) {
      .logo {
        width: 7rem; } }
    .logo img {
      width: 100%;
      height: auto; }
  