/*

    Typography

*/

html, body {
    font-size: 12px;
}

//Text
$primary_fonts: 'Abel', sans-serif;
$secondary_fonts: 'Roboto', sans-serif;
$shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.5);
$primart_font_colour : white!important;

h1 {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
}

h2 {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 1.15rem;
    margin-top: 5px;
    margin-bottom: 5px;
}

h3 {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.13rem;
    margin-top: 5px;
    margin-bottom: 5px;
}

h4 {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 8px;
    margin-bottom: 8px;

}

h5 {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 5px;
    margin-bottom: 5px;

}

.header li {

    font-family: $primary_fonts;
    color : $primart_font_colour;
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.25rem;

}

span, a ,li, p {
    font-family: $secondary_fonts;
    font-weight: 300;
    color : $primart_font_colour;
    line-height: 1.25;
    font-size: 1rem;
    font-size: 1.25rem;
}