/*

    Menu

*/
.menu {
    display: inline;
    position: relative; }
    .menu .menu-button {
      z-index: 3000;
      position: fixed;
      top: 20px;
      right: 20px;
      display: inline-block;
      padding: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #212121;
      border: 0px solid #ccc;
      cursor: pointer; }
    .menu .burger-icon {
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .menu .burger-icon, .menu .burger-icon::before, .menu .burger-icon::after {
        display: block;
        width: 20px;
        height: 3px;
        background-color: #FFFFFF;
        outline: 1px solid transparent;
        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s; }
      .menu .burger-icon::before, .menu .burger-icon::after {
        position: absolute;
        content: ""; }
      .menu .burger-icon::before {
        top: -8px; }
      .menu .burger-icon::after {
        top: 8px; }
    .menu .menu-button.is-active .burger-icon {
      background-color: transparent; }
    .menu .menu-button.is-active .burger-icon::before {
      -webkit-transform: translateY(8px) rotate(45deg);
      -moz-transform: translateY(8px) rotate(45deg);
      -ms-transform: translateY(8px) rotate(45deg);
      -o-transform: translateY(8px) rotate(45deg);
      transform: translateY(8px) rotate(45deg); }
    .menu .menu-button.is-active .burger-icon::after {
      -webkit-transform: translateY(-8px) rotate(-45deg);
      -moz-transform: translateY(-8px) rotate(-45deg);
      -ms-transform: translateY(-8px) rotate(-45deg);
      -o-transform: translateY(-8px) rotate(-45deg);
      transform: translateY(-8px) rotate(-45deg); }
    .menu .menu-body.collapse {
      transition-delay: 0.4s;
      right: calc(((100vw / 31) * 32) * -1); }
    .menu .menu_bg {
      pointer-events: none;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #212121; }
    .menu .menu-body {
      position: fixed;
      right: 0;
      height: calc(100vh + 12px);
      top: -12px;
      display: block;
      width: calc(100%);
      float: left;
      margin-left: 0rem;
      z-index: 100;
      background-color: #212121;
      padding-left: 0;
      transition: right 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.1s;
      z-index: 2500;
      overflow: scroll; }
      .menu .menu-body li {
        margin-left: calc(3.125% + 0rem);
        list-style: none;
        display: block;
        padding-left: 10px;
        text-align: left; }
    .menu .social a {
      display: block;
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      background-repeat: no-repeat;
      background-size: 120px;
      width: 120px;
      display: block; }

  