
#canvas canvas {
    background-color: #212121;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    display: block; 
}
  
canvas {
    background-color: #212121;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    display: block; 
}